import { ActionType, WorkerFriendRequestStatus, WorkerFriendsStore } from "./model";

const initialState: WorkerFriendsStore = {
  workerFriends: { data: null, loading: false },
  workerFriendRequests: {
    [WorkerFriendRequestStatus.SENT]: { data: null, loading: false },
    [WorkerFriendRequestStatus.RECEIVED]: { data: null, loading: false },
  },
  friendLinkAlertProps: null,
};

const workerFriendsStore = (state = initialState, action: any): WorkerFriendsStore => {
  switch (action.type) {
    case ActionType.UPDATE_FRIEND_INVITATIONS:
      return {
        ...state,
        workerFriendRequests: {
          ...state.workerFriendRequests,
          [WorkerFriendRequestStatus.RECEIVED]: action.data,
        },
      };
    case ActionType.UPDATE_CURRENT_FRIENDS:
      return {
        ...state,
        workerFriends: action.data,
      };
    case ActionType.UPDATE_SENT_FRIEND_REQUESTS:
      return {
        ...state,
        workerFriendRequests: {
          ...state.workerFriendRequests,
          [WorkerFriendRequestStatus.SENT]: action.data,
        },
      };
    case ActionType.SHOW_FRIEND_LINK_ALERT:
      return {
        ...state,
        friendLinkAlertProps: action.data,
      };
    default:
      return state;
  }
};

export { workerFriendsStore };
