import { IFriendLinkAlertProps } from "@src/app/components/workWithFriends/interface";

enum WorkerFriendRequestStatus {
  RECEIVED = "RECEIVED",
  SENT = "SENT",
}

enum WorkerFriendRequestAction {
  ACCEPT = "ACCEPT",
  DECLINE = "DECLINE",
}

enum FriendLinkType {
  FRIEND_ALREADY_EXIST = "FRIEND_ALREADY_EXIST",
  OWN_LINK = "OWN_LINK",
  INVALID_LINK = "INVALID_LINK",
  NEW_FRIEND = "NEW_FRIEND",
  DEFAULT = "DEFAULT",
}

interface IWorkerFriend {
  name: string;
  qualification: string;
  hasProfilePic: boolean;
  workerId: string;
  friendId: string;
}

interface IWorkerFriendRequest {
  name: string;
  qualification: string;
  hasProfilePic: boolean;
  workerId: string;
  friendRequestId: string;
  status: WorkerFriendRequestStatus;
}
interface WorkerFriendsStore {
  workerFriends: {
    data: Array<IWorkerFriend> | null;
    loading: boolean;
  };
  workerFriendRequests: {
    [WorkerFriendRequestStatus.RECEIVED]: {
      data: Array<IWorkerFriendRequest> | null;
      loading: boolean;
    };
    [WorkerFriendRequestStatus.SENT]: {
      data: Array<IWorkerFriendRequest> | null;
      loading: boolean;
    };
  };
  friendLinkAlertProps: IFriendLinkAlertProps | null;
}

const ActionType = {
  UPDATE_CURRENT_FRIENDS: "UPDATE_CURRENT_FRIENDS",
  UPDATE_SENT_FRIEND_REQUESTS: "UPDATE_SENT_FRIEND_REQUESTS",
  UPDATE_FRIEND_INVITATIONS: "UPDATE_FRIEND_INVITATIONS",
  SHOW_FRIEND_LINK_ALERT: "SHOW_FRIEND_LINK_ALERT",
};

export {
  ActionType,
  WorkerFriendsStore,
  WorkerFriendRequestStatus,
  IWorkerFriend,
  WorkerFriendRequestAction,
  IWorkerFriendRequest,
  FriendLinkType,
};
