import {
  fetchWorkerFriendRequests,
  fetchWorkerFriends,
} from "@src/app/components/workWithFriends/api";
import { Dispatch } from "redux";

import {
  ActionType,
  IWorkerFriend,
  IWorkerFriendRequest,
  WorkerFriendRequestStatus,
} from "./model";

export const fetchAndSetWorkerFriends = async (dispatch: Dispatch): Promise<void> => {
  dispatch({
    type: ActionType.UPDATE_CURRENT_FRIENDS,
    data: { loading: true, data: null },
  });
  const result = (await fetchWorkerFriends()) ?? [];
  dispatch({
    type: ActionType.UPDATE_CURRENT_FRIENDS,
    data: { loading: false, data: result },
  });
};

export const fetchAndSetWorkerFriendRequests = async (
  dispatch: Dispatch,
  status: WorkerFriendRequestStatus
): Promise<void> => {
  const actionType =
    status === WorkerFriendRequestStatus.RECEIVED
      ? ActionType.UPDATE_FRIEND_INVITATIONS
      : ActionType.UPDATE_SENT_FRIEND_REQUESTS;
  dispatch({ type: actionType, data: { loading: true, data: null } });
  const result = (await fetchWorkerFriendRequests(status)) ?? [];
  dispatch({ type: actionType, data: { loading: false, data: result } });
};

export const setWorkerFriendsData = (
  dispatch: Dispatch,
  newWorkerFriends: IWorkerFriend[]
): void => {
  dispatch({
    type: ActionType.UPDATE_CURRENT_FRIENDS,
    data: { loading: false, data: newWorkerFriends },
  });
};

export const setWorkerFriendRequestsData = (
  dispatch: Dispatch,
  status: WorkerFriendRequestStatus,
  newWorkerFriendRequests: IWorkerFriendRequest[]
): void => {
  const actionType =
    status === WorkerFriendRequestStatus.RECEIVED
      ? ActionType.UPDATE_FRIEND_INVITATIONS
      : ActionType.UPDATE_SENT_FRIEND_REQUESTS;
  dispatch({
    type: actionType,
    data: { loading: false, data: newWorkerFriendRequests },
  });
};
