import moment from "moment";

import { ActionType, IUnverifiedShiftState, ShiftAction, ShiftStore } from "./model";

const initialState: ShiftStore = {
  isLocationAvailable: true,
  undoShiftActions: {},
  showToast: "",
  myShifts: {},
  unverifiedShiftsByDate: {},
  fcmRequestsData: [],
};

const shiftStore = (state = initialState, action: ShiftAction): ShiftStore => {
  switch (action.type) {
    case ActionType.UPDATE_MY_SHIFT_DATE_RANGE:
      return {
        ...state,
        myShiftDateRange: action.data?.myShiftDateRange,
      };
    case ActionType.SET_SHOW_TOAST:
      return {
        ...state,
        showToast: action.data?.showToast ?? "",
      };

    case ActionType.UPDATE_OPEN_SHIFT_DATE_RANGE:
      return {
        ...state,
        openShiftDateRange: action.data?.openShiftDateRange,
      };

    case ActionType.UPDATE_LOCATION_AVAILABILITY:
      return {
        ...state,
        isLocationAvailable: false,
      };

    case ActionType.UNDO_INTEREST:
      return {
        ...state,
        undoShiftActions: action.data?.undoShiftActions as ShiftStore["undoShiftActions"],
      };

    case ActionType.UPDATE_SHARED_SHIFT_SLOT:
      return {
        ...state,
        sharedShiftSlot: action.data?.sharedShiftSlot,
      };
    case ActionType.GET_MY_SHIFTS_GROUPED_BY_DATE: {
      const myShifts = { ...(action.data?.myShifts ?? {}) };
      return {
        ...state,
        myShifts,
      };
    }
    case ActionType.GET_UNVERIFIED_AGENT_SHIFTS: {
      const unverifiedShifts = action.data?.unverifiedShifts;
      const unverifiedShiftsExceptToday = unverifiedShifts?.filter(
        (item) => moment() > moment(item.start)
      );
      const unverifiedShiftsByDate = (
        unverifiedShiftsExceptToday ?? []
      ).reduce<IUnverifiedShiftState>((acc, curr) => {
        const day = moment(curr.start).format("YYYY-MM-DD");
        if (!Object.hasOwn(acc, day)) {
          acc[day] = [];
        }
        acc[day].push(curr);
        return acc;
      }, {});
      return {
        ...state,
        unverifiedShiftsByDate,
      };
    }
    case ActionType.SET_FCM_REQUESTS:
      return {
        ...state,
        fcmRequestsData: action.data?.fcmRequestsData ?? [],
      };

    default:
      return state;
  }
};

export { shiftStore };
