import { ActionType } from "./model";

const initialState = {
  experience: null,
  hasSedationExperience: false,
  hasTrayAssemblyExperience: false,
  experienceDetails: {},
};

const specialitiesStore = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.GET_SPECIALITIES_DATA:
      return {
        ...state,
        experience: action.data.experience,
        hasSedationExperience: action.data.hasSedationExperience,
        hasTrayAssemblyExperience: action.data.hasTrayAssemblyExperience,
        // TODO: This is most likely to be removed
        experienceDetails: action.data.experienceDetails,
      };

    default:
      return state;
  }
};

export { specialitiesStore };
