import { fetchFacilitiesVerificationPreferences } from "@src/app/api/facility";
import { fetchCurrentTimezone } from "@src/app/dayView/api";
import { ISharedShiftSlot } from "@src/app/dayView/constants";
import { FCMRequestData } from "@src/app/pendingSentHomeRequests/interfaces";
import { fetchAgentUnverifiedShifts } from "@src/app/store/ongoingShifts/apiV2";
import { addVerificationPreferencesToShiftFacility } from "@src/app/utils/shiftHelper";
import { logError } from "@src/appV2/lib/analytics";
import { Shift } from "@src/lib/interface";
import moment from "moment-timezone";
import { Dispatch } from "redux";

import { ActionType } from "./model";

const groupByShiftName = (shift: Shift): string => {
  return shift.name as string;
};

const updateundoShiftActions = (dispatch: Dispatch, undoShiftActions: any) => {
  localStorage.setItem("isSignup", "true");
  dispatch({
    type: ActionType.UNDO_INTEREST,
    data: undoShiftActions,
  });
};

const setShowToast =
  (toastName = "") =>
  (dispatch: Dispatch) => {
    dispatch({
      type: ActionType.SET_SHOW_TOAST,
      data: { showToast: toastName },
    });
  };

const updateSharedShiftSlot = (sharedShiftSlot: ISharedShiftSlot | null) => ({
  type: ActionType.UPDATE_SHARED_SHIFT_SLOT,
  data: {
    sharedShiftSlot,
  },
});

const getAgentUnVerifiedShifts = () => async (dispatch) => {
  try {
    await fetchCurrentTimezone();
    const _unverifiedShifts = await fetchAgentUnverifiedShifts();
    const facilityIds = _unverifiedShifts.map((shift) => shift.facility?.userId).filter(Boolean);
    let unverifiedShifts = _unverifiedShifts.sort(
      (a, b) => moment(b.start).valueOf() - moment(a.start).valueOf()
    );
    if (facilityIds.length) {
      const { data: facilitiesPreferences } = await fetchFacilitiesVerificationPreferences(
        facilityIds as string[]
      );
      unverifiedShifts = addVerificationPreferencesToShiftFacility(
        unverifiedShifts,
        facilitiesPreferences
      );
    }
    dispatch({
      type: ActionType.GET_UNVERIFIED_AGENT_SHIFTS,
      data: {
        unverifiedShifts,
      },
    });
  } catch (err) {
    logFailureEvent(ActionType.GET_UNVERIFIED_AGENT_SHIFTS, err);
  }
};

const setFcmRequestsData = (fcmRequestsData: FCMRequestData[]) => ({
  type: ActionType.SET_FCM_REQUESTS,
  data: {
    fcmRequestsData,
  },
});

const logFailureEvent = (type: string, error: Error) => (dispatch, getState) => {
  logError("Error while fetching shifts", { error });
  dispatch({
    type: `${type}_FAILURE`,
    data: { error },
  });
};

export {
  groupByShiftName,
  updateundoShiftActions,
  setShowToast,
  updateSharedShiftSlot,
  getAgentUnVerifiedShifts,
  setFcmRequestsData,
};
