import { ISharedShiftSlot } from "@src/app/dayView/constants";
import { FCMRequestData } from "@src/app/pendingSentHomeRequests/interfaces";
import { Shift, UnverifiedShift } from "@src/lib/interface";
import { DateRange } from "@src/lib/utils";

export interface ShiftStore {
  myShiftDateRange?: DateRange;
  openShiftDateRange?: DateRange;
  isLocationAvailable: boolean;
  undoShiftActions: Record<string, UndoInterestItem>;
  showToast: string;
  sharedShiftSlot?: ISharedShiftSlot;
  myShifts: ShiftDateMap;
  unverifiedShiftsByDate: IUnverifiedShiftState;
  fcmRequestsData: FCMRequestData[];
}

export interface IUnverifiedShiftState {
  [date: string]: UnverifiedShift[];
}

interface UndoInterestItem {
  isContinue: boolean;
  isUndo: boolean;
  isCanceledShift?: boolean;
  isRemovedInterest?: boolean;
  reasonType?: string;
  reasonDescription?: string;
}

export interface ShiftDateMap {
  [date: string]: Array<Required<Shift>>;
}

export const ActionType = {
  CLEAR_SHIFTS: "CLEAR_SHIFTS",
  UPDATE_MY_SHIFT_DATE_RANGE: "UPDATE_MY_SHIFT_DATE_RANGE",
  UPDATE_OPEN_SHIFT_DATE_RANGE: "UPDATE_OPEN_SHIFT_DATE_RANGE",
  UPDATE_LOCATION_AVAILABILITY: "UPDATE_LOCATION_AVAILABILITY",
  UNDO_INTEREST: "UNDO_INTEREST",
  SET_SHOW_TOAST: "SET_SHOW_TOAST",
  UPDATE_SHARED_SHIFT_SLOT: "UPDATE_SHARED_SHIFT_SLOT",
  GET_MY_SHIFTS_GROUPED_BY_DATE: "GET_MY_SHIFTS_GROUPED_BY_DATE",
  GET_UNVERIFIED_AGENT_SHIFTS: "GET_UNVERIFIED_AGENT_SHIFTS",
  SET_FCM_REQUESTS: "SET_FCM_REQUESTS",
};

interface ShiftActionData extends ShiftStore {
  updatedShift: Shift;
  unverifiedShifts: UnverifiedShift[];
}

export interface ShiftAction {
  type: string;
  data?: Partial<ShiftActionData>;
}
